import { ref } from 'vue';

interface FileData {
  url: string;
  name: string;
  file: File | null;
  id: string | null;
}

interface FileUploadResult {
  uploadFile: {
    url: string;
    name: string;
    id: string | null;
    file: File | null;
  };
  previewUrl: string;
}

const loadImage = ref();
const isImageBeingCropped = ref(false);
const isFileBeingCropped = ref(false);
const TARGET_SIZE = 800;

const dataURLtoFile = async (dataurl: any, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let File = window.File;
  if ('File_native' in window) {
    File = (window as any)['File_native'];
  }
  return new File([u8arr], filename, { type: mime });
};

const onFileInputChange = (e: any) => {
  isImageBeingCropped.value = true;
  if (e.target?.files) {
    Object.values(e.target.files)?.forEach((file: any) => {
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = function (event: any) {
          const blob = new Blob([event.target.result]);
          window.URL = window.URL || window.webkitURL;
          const blobURL = window.URL.createObjectURL(blob);

          const image = new Image();
          image.src = blobURL;

          image.onload = async function () {
            const resizedImageDataUrl = await getResizedImage(image, file.size / 1000);
            const resizedImageFile = await dataURLtoFile(resizedImageDataUrl, file.name);
            loadImage.value = { url: resizedImageDataUrl, file: resizedImageFile };
            isImageBeingCropped.value = false;
          };
        };
      } catch (error) {
        console.error('failed to generate URl for loaded image: ', file);
      }
    });
    e.target.value = '';
  }
};

const MAX_FILE_SIZE = 50 * 1024 * 1024;
const onAllTypeFileInputChange = async (e: Event): Promise<FileUploadResult | null> => {
  const target = e.target as HTMLInputElement;
  if (!target.files || target.files.length === 0) {
    return null;
  }

  const file = target.files[0]; // We'll only handle the first file

  if (file.size > MAX_FILE_SIZE) {
    target.value = '';
    throw new Error('ERR_SIZE_FILE');
  }

  try {
    const fileData = await readFileAsArrayBuffer(file);
    const uploadFile = await handleFile(fileData);

    const result: FileUploadResult = {
      uploadFile: {
        url: uploadFile.url,
        name: file.name,
        id: null,
        file: uploadFile.file,
      },
      previewUrl: uploadFile.url,
    };

    return result;
  } catch (error) {
    target.value = '';
    console.error('File upload error:', error);
    throw new Error('ERR_UPLOAD_FILE');
  } finally {
    target.value = ''; // Clear the input
  }
};

const readFileAsArrayBuffer = (file: File): Promise<{ url: string; file: File }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const arrayBuffer = event.target?.result as ArrayBuffer;
      const blob = new Blob([arrayBuffer]);
      const blobURL = window.URL.createObjectURL(blob);
      resolve({ url: blobURL, file });
    };
    reader.onerror = () => reject(new Error('File reading error'));
    reader.readAsArrayBuffer(file);
  });
};
// Función para manejar los archivos en función de si son imágenes o no
const handleFile = async (fileData: { url: string; file: File }): Promise<FileData> => {
  return new Promise((resolve, reject) => {
    // If it's an image
    if (fileData.file.type.startsWith('image/')) {
      const image = new Image();
      image.src = fileData.url;

      image.onload = async function () {
        try {
          const resizedImageDataUrl = await getResizedImage(image, fileData.file.size / 1000);
          const resizedImageFile = await dataURLtoFile(resizedImageDataUrl, fileData.file.name);

          resolve({
            url: resizedImageDataUrl,
            name: resizedImageFile.name,
            file: resizedImageFile,
            id: null,
          });
        } catch (error) {
          reject(error instanceof Error ? error : new Error(String(error)));
        }
      };

      image.onerror = function (error) {
        reject(error instanceof Error ? error : new Error(String(error)));
      };
    } else {
      // For non-image files
      resolve({
        url: fileData.url,
        name: fileData.file.name,
        file: fileData.file,
        id: null,
      });
    }
  });
};

async function getResizedImage(img: HTMLImageElement, givenSize: number) {
  const canvas = document.createElement('canvas');
  const width = img.naturalWidth;
  const height = img.naturalHeight;
  canvas.width = width;
  canvas.height = height;

  const qualityDropRatio = TARGET_SIZE > givenSize ? 1 : TARGET_SIZE / givenSize;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height);
  }
  return canvas.toDataURL('image/jpeg', qualityDropRatio);
}

function deleteLoadedImages() {
  loadImage.value = null;
}

export {
  onFileInputChange,
  getResizedImage,
  loadImage,
  isImageBeingCropped,
  deleteLoadedImages,
  onAllTypeFileInputChange,
  isFileBeingCropped,
  FileData,
};
